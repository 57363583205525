import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableShareTargetList() {
  // Use toast
  const toast = useToast()

  const refShareTargetListTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('ShareTarget') }),
    name: `${t('Line Liff')} » ${t('Name')} » ${t('ID')}`,
    json: `${t('Share')} - ${t('Target')} » ${t('JSON')} » ${t('Link')}`,
    status: `${t('Status')} » ${t('Date/Time')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true, label: msgList.name },
    { key: 'json', sortable: false, label: msgList.json },
    { key: 'status', sortable: false, label: msgList.status },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalShareTargets = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')
  const lineLiffFilter = ref('ALL')
  const nameFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refShareTargetListTable.value ? refShareTargetListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalShareTargets.value,
    }
  })

  const refetchData = () => {
    refShareTargetListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale, lineLiffFilter, nameFilter], () => {
    refetchData()
  })

  const getListShareTarget = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      lineLiff: lineLiffFilter.value,
      name: nameFilter.value,
    }
    store
      .dispatch('sharetarget-store/getListShareTarget', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalShareTargets.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-share-target-list`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isStatusVariant = isstatus => {
    if (isstatus === 'active') return 'success'
    if (isstatus === 'inactive') return 'danger'
    return 'danger'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    return ''
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    getListShareTarget,
    tableColumns,
    perPage,
    currentPage,
    totalShareTargets,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refShareTargetListTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,

    lineLiffFilter,
    nameFilter,
  }
}
